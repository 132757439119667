import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';




const pages = [
  { link: 'Care (Very Much)', loc: "https://cassanicampbell.bandcamp.com/album/care-very-much", tip: "Buy our album!" },
  { link: 'Poet Shuts Clock', loc: "https://sunnysiderecords.bandcamp.com/album/poet-shuts-clock", tip: "Buy our album!" },
  { link: 'Watch', loc: "#vids", tip: "Watch our videos!" },
];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };


  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };



  return (
    <AppBar position="fixed" color="transparent" enableColorOnDark sx={{ backdropFilter: "blur(25px)" }}>
      <Container maxWidth="xl" >
        <Toolbar disableGutters>
          <Typography
            variant="h2"
            noWrap
            component="a"
            href="https://www.robertocassani.com"
            sx={{
              mr: 1,
              display: { xs: 'none', md: 'flex' },
              letterSpacing: '.3rem',
              color: 'tomato',
              textShadow: '3px 3px 5px rgba(0,0,0,0.5)',

              textDecoration: 'none',
            }}
          >
            cassani
          </Typography>
          <Typography
            variant="h2"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 1,

              display: { xs: 'none', md: 'flex' },
              letterSpacing: '.3rem',
              color: 'white',
              textShadow: '3px 3px 5px rgba(0,0,0,0.5)',

              textDecoration: 'none',
            }}
          >
            /
          </Typography>
          <Typography
            variant="h2"
            noWrap
            component="a"
            href="https://www.fraseracampbell.com"
            sx={{
              mr: 1,
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              letterSpacing: '.3rem',
              color: 'dodgerblue',
              textShadow: '3px 3px 5px rgba(0,0,0,0.5)',

              textDecoration: 'none',
            }}
          >
            campbell
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'flex', md: 'none' },
                flexDirection: 'column'
              }}
            >
              {pages.map((page) => (
                <MenuItem component={Button} href={page.loc} key={page.link} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page.link}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              letterSpacing: '.3rem',
              textShadow: '3px 3px 5px rgba(0,0,0,0.5)',
              color: 'tomato',
              textDecoration: 'none',
            }}
          >
            cassani
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              letterSpacing: '.3rem',
              textShadow: '3px 3px 5px rgba(0,0,0,0.5)',
              color: 'white',
              textDecoration: 'none',
            }}
          >
            /
          </Typography>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              letterSpacing: '.3rem',
              textShadow: '3px 3px 5px rgba(0,0,0,0.5)',
              color: 'dodgerblue',
              textDecoration: 'none',
            }}
          >
            campbell
          </Typography>
          <Box sx={{ flexGrow: 2, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Tooltip title={page.tip} placement="bottom-start">
                <Button
                  className="link-button"
                  key={page.link}
                  onClick={handleCloseNavMenu}
                  href={page.loc}
                  sx={{
                    my: 3,
                    color: 'white',
                    textShadow: '2px 2px 4px rgba(0,0,0,0.2)',
                    display: 'block',
                    boxShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                    borderRadius: 4,
                    marginRight: 3
                  }}

                ><Typography variant="body1">
                    {page.link}
                  </Typography>
                </Button>
              </Tooltip>
            ))}
          </Box>
          <Tooltip title="Send us a note!" placement="bottom-start">
            <IconButton size='large' sx={{ textDecoration: 'none', color: 'white' }} href="mailto:roberto@robertocassani.com?subject=hello%20cassani%20campbell!">
              <MailOutlineIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
