

const textDialog = {textTitle: "Cantico",
textURL: "https://www.youtube.com/embed/-fIQUZH9PQw?si=dj0s3wyfMBgyX4iA" ,
textInfo: `
Roberto Cassani - bass, vocals

Fraser A. Campbell - saxophone / whistle

Ross Ainslie - whistles

Graeme Stephen - guitar

Doug Hough - drums`};


export function CanticoText() {
  return textDialog;
};
