import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MusicVideoIcon from '@mui/icons-material/MusicVideo';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';


const actions = [
  { icon: <MailOutlineIcon />, name: 'Send us a note!', loc: "mailto:roberto@robertocassani.com" },
  { icon: <MusicVideoIcon />, name: 'Watch our videos!', loc: "#vids" },
  { icon: <MusicNoteIcon />, name: 'Care (Very Much)', loc: "https://cassanicampbell.bandcamp.com/album/care-very-much" },
  { icon: <MusicNoteIcon />, name: 'Poet | Shuts | Clock', loc: "https://sunnysiderecords.bandcamp.com/album/poet-shuts-clock" },
  { icon: <ArrowUpwardIcon />, name: 'Back to the top!', loc: "#top" }
];

export default function SimpleSpeedDial() {

  return (
    <Box sx={{ flexGrow: 1 }}>
      <SpeedDial
        ariaLabel="Links"
        sx={{ position: 'fixed', bottom: "1rem", right: "1rem" }}
        icon={<SpeedDialIcon />}
        FabProps={
          {
            sx: {
              bgcolor: 'transparent',
              backdropFilter: "blur(20px)",
              '&:hover': {
                bgcolor: 'rgba(50,50,50,0.3)',
              }
            }
          }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            href={action.loc}
          />
        ))}
      </SpeedDial>
    </Box>
  );
}
