

const textDialog = {textTitle: 'Care (Very Much)',
textURL: 'https://bandcamp.com/EmbeddedPlayer/album=482709498/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/' ,
textInfo: `
Made in Perth, Cassani / Campbell are the utterly unique Italian double bassist and songwriter, Roberto Cassani, and the hugely gifted New York-based saxophonist and composer Fraser Campbell.

Their debut album arose from outdoor lockdown jams in Perth (Fraser's hometown and Roberto’s adopted home) and its title poet / shuts / clock is the geolocation tag for the bench on the North Inch where they’d meet to play. Released on New York jazz label Sunnyside, it embraces spontaneous lyrics, quirky spoken word, bossa nova and the blues to create an unforgettable listening experience.

Earlier in lockdown, Roberto released Ansema, a joyous album of songs in the little-heard Rivoltano dialect of his hometown in northern Italy. Recorded with some of Scotland’s finest traditional musicians, the album rightly received rave reviews.

credits

releases January 19, 2024

Roberto Cassani - bass, vocals, lyrics

Fraser A. Campbell - saxophone, whistle

Ross Ainslie - whistles

Graeme Stephen - guitar

Doug Hough - drums`};


export function CareText() {
  return textDialog;
};
