

const textDialog = {textTitle: "Moo!",
textURL: "https://www.youtube.com/embed/CSZ1LYfilvg" ,
textInfo: `Words are the root of plenty trouble
Like the inflammation of the appendix
WORDS cause a lot of pain and struggle
They’re often foul, cheeky and offensive

How dare somebody speak their mind eh?!!
None of their business anyhoo!
Words are the root of plenty trouble
We should behave more like the coos

They just moo!…
that’s how they’re happily co-existing.
Just say moo!…
Although I’ve lately started thinking (that)…

Someone WILL find your moos offensive
It’s paradoxical, but true.
FOLKS are the root of every trouble!
Yet I love everybody!…Moo!

Roberto Cassani - bass, vocals, other instruments

Fraser A. Campbell - woodwinds, other instruments, production`};


export function MooText() {
  return textDialog;
};
