

const textDialog = {textTitle:"L'Amore",
textURL: "https://www.youtube.com/embed/e8uHBYgBk0M",
textInfo: `Oh… l’amore
(Love) is not
Like the Lochness monster: difficult to spot…
But a 20/20 vision wouldn’t be enough
If you’ve built a massive wall around your heart

(You see these) Walls
Around the ticker
Don’t let in the light that passes through your peepers
So you go about the wonders of creation,
Just bamboozled by a kerfuffle of emotions

Ah, but drop those barriers,
And whoosh!... Your heart
Takes a flight to those it feels right
And those you let in our heart
Quite quickly...voila’…
Look great to the eye

And THAT’s AMORE!!
And once it’s in
It’s the lodger that is never going to leave…
And rebuilding walls and borders you once knew
Would be pointless,
Because love would float straight through.

Roberto Cassani - bass, vocals, other instruments

Fraser A. Campbell - woodwinds, other instruments, production`};


export function LAmoreText() {
  return textDialog;
};
