import * as React from 'react';
import theCoo from './images/coo_op_moo.png';
import './App.css';




export default function MooCoo() {

  return (
    <div >
      <img className="moo" src={theCoo} alt="moo!"/>
    </div>
  );
}
