import * as React from 'react';
// import album from './poetshutsclock.jpg';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ScrollDialog from './Dialog.js';
import Tooltip from '@mui/material/Tooltip';

const OpenDialog = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function CareCard({ mediaInfo }) {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const info = mediaInfo();


    return (
        <Card className="link-button" elevation={8} sx={{ maxWidth: '70vw', maxHeight: '80vh', borderRadius: 3, border: 3, borderColor: 'white', marginTop: 2, marginBottom: 2 }}>

            <CardMedia
                component="iframe"

                src={info.textURL}
                alt="care (very much)"
                sx={{ minHeight: '46vh', maxHeight: '80vh' }}
            />
            <CardContent sx={{ display: { xs: 'none', md: 'block' } }}>
                <Typography paragraph variant="body2" color="text.secondary">
                    <a href="https://www.robertocassani.com" target="_blank" rel="noreferrer" >Roberto Cassani</a> - bass, vocals, other instruments
                </Typography>
                <Typography paragraph variant="body2" color="text.secondary">
                    <a href="https://www.fraseracampbell.com" target="_blank" rel="noreferrer">Fraser A. Campbell</a> - saxophone / whistle       </Typography>
                <Typography paragraph variant="body2" color="text.secondary">
                    <a href="https://www.rossainslie.com/" target="_blank" rel="noreferrer">Ross Ainslie</a> - whistles</Typography>
                <Typography paragraph variant="body2" color="text.secondary">
                    <a href="https://www.graemestephen.net/" target="_blank" rel="noreferrer">Graeme Stephen</a> - guitar
                </Typography>
                <Typography paragraph variant="body2" color="text.secondary">
                    <a href="https://www.allaboutjazz.com/musicians/doug-hough" target="_blank" rel="noreferrer">Doug Hough</a> - drums
                </Typography>
                <Typography paragraph variant="body2" color="text.secondary">
                    releases January 19, 2024
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <IconButton href="https://cassanicampbell.bandcamp.com/album/care-very-much" aria-label="add to favorites">
                    <Tooltip title="Like it? Buy it!" placement="bottom-start">
                        <FavoriteIcon />
                    </Tooltip>
                </IconButton>
                <Typography variant="body2" align="justify">{info.textTitle}</Typography >

                <OpenDialog
                    onClick={handleExpandClick}
                    aria-label="show more"
                >
                    <ScrollDialog dialogBtn={<Tooltip title="Extra goodness..!" placement="bottom-start"><InfoOutlinedIcon fontSize='large' /></Tooltip>} dialogTxt={info.textInfo} dialogTitle={info.textTitle} />
                </OpenDialog>

            </CardActions>
        </Card>
    );
}
