import * as React from 'react';
// import album from './poetshutsclock.jpg';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ScrollDialog from './Dialog.js';
import Tooltip from '@mui/material/Tooltip';

const OpenDialog = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function InfoCard({mediaInfo}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
const info = mediaInfo();


  return (
    <Card className="link-button" elevation={8} sx={{ maxWidth: '70vw', maxHeight:'80vh', borderRadius: 3, border:3, borderColor:'white', marginTop:2, marginBottom:2 }}>

      <CardMedia
        component="iframe"

        src={info.textURL}
        alt="poetshutsclock"
        sx={{minHeight:'46vh',maxHeight:'80vh'}}
      />
      <CardContent sx={{display:{xs: 'none', md: 'block'}}}>
        <Typography paragraph variant="body2" color="text.secondary">
        <a href="https://www.robertocassani.com" target="_blank" rel="noreferrer" >Roberto Cassani</a> - bass, vocals, other instruments
        </Typography>
        <Typography paragraph variant="body2" color="text.secondary">
        <a href="https://www.fraseracampbell.com" target="_blank" rel="noreferrer">Fraser A. Campbell</a> - woodwinds, other instruments, production
        </Typography>
        <Typography paragraph variant="body2" color="text.secondary">
        releases February 10, 2023 on
        </Typography>
        <Typography paragraph variant="body2" color="text.secondary">
        <a href="https://www.sunnysiderecords.com/site/release_detail?id=1138" target="_blank" rel="noreferrer"> Sunnyside Records</a>
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton href="https://sunnysiderecords.bandcamp.com/album/poet-shuts-clock" aria-label="add to favorites">
        <Tooltip title="Like it? Buy it!" placement="bottom-start">
          <FavoriteIcon />
          </Tooltip>
        </IconButton>
        <Typography variant="body2" align="justify">{info.textTitle}</Typography >

        <OpenDialog
          onClick={handleExpandClick}
          aria-label="show more"
        >
        <ScrollDialog dialogBtn={<Tooltip title="Extra goodness..!" placement="bottom-start"><InfoOutlinedIcon fontSize='large' /></Tooltip>} dialogTxt={info.textInfo} dialogTitle={info.textTitle} />
        </OpenDialog>

      </CardActions>
    </Card>
  );
}
