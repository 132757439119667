
import './App.css';

import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import AppBar from './AppBar.js';
import CssBaseline from '@mui/material/CssBaseline';




import ModernSans from './fonts/ModernSans.woff2';

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';

import InfoCard from './Card.js';
import CareCard from './CareCard.js';
import SimpleSpeedDial from './SpeedDial.js'

import { PromoText } from './promoTxt.js';
import { CareText } from './careTxt.js';
import { CanticoText } from './canticoTxt.js';
import { NaeTotherText } from './naeTotherTxt.js';
import { MooText } from './mooTxt.js';
import { GoodnessText } from './goodnessTxt.js';
import { ComfyKleeText } from './comfyKleeTxt.js';
import { LAmoreText } from './lamoreTxt.js';
import MooCoo from './coo.js';

let theme = createTheme({
  typography: {
    fontFamily: 'ModernSans, helvetica',
  },
  components: {
    MuiCssBaseLine: {
      styleOverrides: `
        @font-face {
          font-family: 'ModernSans';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src:local('ModernSans'), local('ModernSans-Regular'), url(${ModernSans}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }`
    }
  }
});
theme = responsiveFontSizes(theme);


function App() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <AppBar />
        <div id="top" className="container" >
          <Grid className="vid-grid" container spacing={3} sx={{ justifyContent: 'center', marginTop: '5rem' }}>
            <Grid item >
              <CareCard mediaInfo={CareText} />
            </Grid>
          </Grid>
        </div>
        <Divider />
        <div id="psc" className="container" >
          <Grid className="vid-grid" container spacing={3} sx={{ justifyContent: 'center', marginTop: '5rem' }}>
            <Grid item >
              <InfoCard mediaInfo={PromoText} />
            </Grid>
          </Grid>
        </div>
        <Divider />
        <div id="vids" className="container">
          <Grid className="vid-grid" container spacing={3} sx={{ justifyContent: 'center', marginTop: '5rem' }}>
          <Grid item >
              <CareCard mediaInfo={NaeTotherText} />
            </Grid>
            <Grid item >
              <CareCard mediaInfo={CanticoText} />
            </Grid>            
            <Grid item >
              <InfoCard mediaInfo={MooText} />
            </Grid>
            <Grid item >
              <InfoCard mediaInfo={GoodnessText} />
            </Grid>
            <Grid item >
              <InfoCard mediaInfo={LAmoreText} />
            </Grid>
            <Grid item >
              <InfoCard mediaInfo={ComfyKleeText} />
            </Grid>
          </Grid>
        </div>
        <footer>
          <div className='footer'>Website designed and coded by <a href='mailto:info@faugproductions.com'>Faug Productions</a></div>
        </footer>
        <SimpleSpeedDial className="link-button" />
        <MooCoo className="moo" />
      </div>

    </ThemeProvider>
  );
}

export default App;
