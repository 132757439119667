

const textDialog = {textTitle:'Goodness Of The Human Heart',
textURL:"https://www.youtube.com/embed/AU9G15n3ah0",
textInfo: `Some of the things
That are very nice to believe in
Despite the ssssshoogly evidence

A Heaven above
A big Santa Claus
And the goodness of the human heart

Some of the things
I quite like to believe in
Despite the shoogly-doogly evidence

A Heaven above
A big Santa Claus
And the goodness of the human heart

Roberto Cassani - bass, vocals, other instruments

Fraser A. Campbell - woodwinds, other instruments, production`};


export function GoodnessText() {
  return textDialog;
};
