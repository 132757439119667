

const textDialog = {textTitle: "Nae Tother A Baw",
textURL: "https://www.youtube.com/embed/Unvosw2N4Dg?si=bPxHNdhEeTvDaLpw" ,
textInfo: `
Roberto Cassani - bass, vocals

Fraser A. Campbell - saxophone / whistle

Ross Ainslie - whistles

Graeme Stephen - guitar

Doug Hough - drums`};


export function NaeTotherText() {
  return textDialog;
};
