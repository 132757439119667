

const textDialog = {textTitle: 'Comfy Klee',
textURL: "https://www.youtube.com/embed/8btjnp3zykU" ,
textInfo: `There is much to be said for being in the right place at the right time. Circumstances caused by the pandemic found many people locked down in places they hadn’ t anticipated.Though there were likely many who were discouraged by this, there were just as many who found purpose and took advantage of their surroundings and the collaborators waiting there.Such is the story of Roberto Cassani and Fraser A.Campbell’ s poet / shuts / clock, a recording made possible by their will to find creative connection in a time of forced separation.

Upon the onset of the pandemic, saxophonist Campbell decided to move back to his hometown of Perth, Scotland. He had already spent ten years in the States, where he had finished his studies at Berklee School of Music and William Paterson and had set up home in New York City. Back at home and needing constructive collaboration, Campbell re-established a connection with Cassani, whom he had met at a jam session in a local pub a year prior.

Cassani originally hails from Northern Italy, where he began writing unusual lyrics and music, getting signed to a major record label at 18 years old. Cassani left Italy for the United Kingdom, eventually settling in Scotland and embedding himself in the local singer/songwriter and folk music scenes. His interest in jazz and his abilities on the double bass, partly due to his mentorship with folk great and bassist Danny Thompson, led him to the local jazz jams, where Cassani met Campbell.

Rules established by the local government forbade Campbell and Cassani from meeting indoors. So, they began to meet at Perth’s South Inch Park for coffee. Talk of music quickly led to talk of playing together, so they began to meet regularly to jam at North Inch Park in order to workshop tunes. The title of the poet / shuts / clock recording comes from a geolocation website that uses words to pinpoint locations. These particular words locate the park bench upon which Campbell and Cassani developed their musical partnership, one that had a strong rapport allowing music to develop quickly.

Cassani had a relationship with Robin Wynn Evans, who owned TPot Recording Studio, which happened to be located in the gorgeous countryside outside of Perth. They arranged to set up a performance at TPot that was broadcast online. With that success, they arranged for more time at the studio and returned at the beginning of January 2022 with a collection of material composed by the two of them, much of it based around the way Cassani constructs lyrics.

The two musicians reported to TPot only with their immediate tools: Campbell with his saxophone and Cassani with his bass and voice. Their freedom to improvise within the confines of the studio allowed Campbell to utilize other elements in the room, including the piano. Once recorded, Campbell took what they had and added further production, like scored instrumentation and tape effects.

The recording begins with Campbell’s “Goodness of The Human Heart,” a great establishing piece that grew spontaneously. The circuitous melody is inspired by the pieces of Paul Motian with a dash of folk flavor. Cassani provided nearly spontaneous lyrics, which made Campbell have to respond in kind with an instrumental accompaniment. The whimsical “Comfy Klee” imagines painter Paul Klee sitting down to begin painting, as Campbell’s quiet melody goes around and around as the harmony changes. Cassani’s chimeric “Peacefully” imagines a fictitious world where universal peace has been announced by a Fellini-esque town crier and his band. On the appropriately pastoral “Moo,” Cassani addresses his love/hate relationship with words and contemplates whether it would be better to be like the cows, animals that have only one expression. Though, even a simple moo might be misconstrued.

Being a fan of Brazilian music and the joy that seems inherent in it, Campbell had issues presenting that joyous feeling in such a dour time. On “Rotondo,” Campbell found a way to write a circular piece that just creatively restrains the mirth that the form seems to require. The happy-go-lucky Cassani wrote his quasi-blues “Look at Him” to consider the audacity some people find in optimists. The program concludes with Cassani’s “L’amore,” a piece that was born quickly in a moment of inspiration while staying with Danny Thompson in London. The bass driven tune is a perfect, meditative conclusion for the recording.

The music of Roberto Cassani and Fraser A. Campbell emerged from a specific place and time. Faced with a lockdown and the limitations that it wrought, the two musicians were able to discover one another and use the time to create a thoughtful and interesting new recording with poet / shuts / clock.
credits

releases February 10, 2023

Roberto Cassani - bass, vocals, other instruments

Fraser A. Campbell - woodwinds, other instruments, production`};


export function ComfyKleeText() {
  return textDialog;
};
